.profilePopoverContainer {
  position: relative;
  display: inline-block;
}

.profilePopoverWrapper {
  background-color: transparent;
  padding: 4px;
  position: absolute;
  top: 100%;
  right: -16px;
  width: 300px;
  padding: 16px;
  z-index: 2;
}

.profilePopoverContainer .profilePopoverWrapper {
  visibility: hidden;
}

.profilePopoverContainer:hover .profilePopoverWrapper {
  visibility: visible;
}

.profilePopover {
  border: var(--border);
  border-radius: 8px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 16px;
}

.profilePopover div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profilePopover p {
  color: var(--black);
  font-weight: 600;
  font-size: 1.2rem;
  width: 100%;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  margin-top: 4px;
  text-overflow: ellipsis;
  text-align: center;
}

.profilePopover a {
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
}

.profilePopover a:hover {
  background-color: var(--light-blue);
}

.profilePopover hr {
  margin: 16px 0;
  border: none;
  border-top: var(--border);
}

.profilePopover button {
  padding: 0px 16px;
}

.profilePopover button:hover {
  color: var(--blue);
}